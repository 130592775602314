<template>
  <div class="relative notification">
    <div
      :id="`button-notifications-${showNotifications ? 'show' : 'hide'}`"
      class="notification__button"
      @click="switchNotications"
    >
      <Icon icon="bell" color="#c4c8df" width="20" height="20" />

      <div v-if="notSeenNotifications && notSeenNotifications.length > 0" class="absolute notification__button-circle">
        {{ notSeenNotifications.length }}
      </div>
    </div>

    <div v-if="showNotifications" v-click-outside="switchNotications" class="notification__popup">
      <div class="notification__popup-top">
        <Title title="Notifications" />

        <Button
          class="ml-auto"
          :label="showSeen ? 'Hide seen' : 'Show all'"
          kind="link"
          @clicked="showSeen = !showSeen"
        />
      </div>

      <div v-if="notifications && notifications.length" class="notification__list">
        <div
          v-for="(notification, notificationIndex) of notifications"
          :key="notificationIndex"
          class="notification__list-item pointer"
          @click="handleNotificationClick(notification)"
        >
          <p v-if="notification.type === 'missing-language'">
            Please set your broadcasting language in the settings page.
          </p>

          <p v-else-if="notification.type === 'campaign-invite'">
            You have been invited to join
            <span class="font-bold">
              {{ notification.data.campaignTitle }}
            </span>
          </p>

          <p v-else-if="notification.type === 'campaign-accepted'">
            You have been accepted for campaign
            <span class="font-bold">
              {{ notification.data.campaignTitle }}
            </span>
          </p>

          <p v-else-if="notification.type === 'campaign-rejected'">
            You have been rejected for campaign
            <span class="font-bold">
              {{ notification.data.campaignTitle }}
            </span>
          </p>

          <p v-else-if="notification.type === 'campaign-join-request'">
            <span class="font-bold capitalize">
              {{ notification.data.contentCreatorUsername }}
            </span>
            asked to join
            <span class="font-bold capitalize">
              {{ notification.data.campaignTitle }}
            </span>
          </p>

          <p v-else-if="notification.type === 'organization-invite'">
            You have been invited to join {{ notification.data.organizationTitle }}
          </p>

          <p v-else>
            {{ notification.type }}
          </p>

          <p class="notification__list-date">
            {{ new Date(notification.createdDate).toDateString() }}
          </p>
        </div>
      </div>

      <div v-else class="notification__list">
        <div class="notification__list-item pointer">
          <p>No new notifications to show</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import vClickOutside from "v-click-outside";
import { Title, Icon, Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "Notification",

  components: {
    Icon,
    Button,
    Title,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      showNotifications: false,
      showSeen: false,
    };
  },

  computed: {
    ...mapGetters(["getNotifications"]),

    notSeenNotifications() {
      log(`notSeenNotifications`);

      if (!this.getNotifications) {
        return null;
      }

      return this.getNotifications.filter((n) => n.seenDate === null);
    },

    notifications() {
      log(`notifications`);

      return this.showSeen ? this.getNotifications : this.notSeenNotifications;
    },
  },

  methods: {
    ...mapActions(["fetchNotifications"]),

    switchNotications() {
      this.showNotifications = !this.showNotifications;
    },

    /**
     * @param {{ _id: string, obj_id: string, obj_type: string, type:string, data: any, createdDate: string, seenDate: string}} notification
     */
    async handleNotificationClick(notification) {
      log({ msg: `handleNotificationClick `, notification });

      this.showNotifications = false;

      if (notification.type === "campaign-join-request") {
        this.$router.push(`/campaigns/${notification.data.campaignId}`);
      } else if (notification.type === "missing-language") {
        this.$router.push(`/settings`);
      } else if (notification.type === "campaign-invite") {
        // this.$router.push(`/dashboard`);
      } else if (notification.type === "campaign-accepted") {
        // this.$router.push(`/dashboard`);
      } else if (notification.type === "campaign-rejected") {
        // this.$router.push(`/dashboard`);
      } else if (notification.type === "organization-invite") {
        this.$router.push(`/settings?page=3`);
      }

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/notification/mark-seen/${notification._id}`;

      await axios
        .put(URL)
        .then((res) => {
          log(`handleNotificationClick res ${res}`);
        })
        .catch((error) => {
          console.error("handleNotificationClick error", error);
        });

      this.fetchNotifications();
    },
  },
};
</script>

<style lang="scss">
@import "../styles/colors";

.notification {
  .notification__button {
    width: 40px;
    height: 60px;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    place-self: center;
    cursor: pointer;

    .notification__button-circle {
      background-color: #d64646;
      width: 24px;
      height: 24px;
      display: flex;
      place-content: center;
      place-items: center;
      place-self: center;
      border-radius: 50%;
      font-size: 0.75rem;
      top: 0;
      right: 0;
      z-index: 1000;
    }
  }

  .notification__popup {
    width: 16rem;
    position: absolute;
    background-color: rgb(35, 29, 58);
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    border: 1px solid color(blue, darker);

    .notification__popup-top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid color(blue, darker);
      width: 100%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      border-radius: 5px 5px 0 0;
      background-color: color(blue, base);
    }

    .notification__list {
      .notification__list-item {
        padding: 0.25rem 0.5rem;

        &:not(:last-child) {
          border-bottom: 1px solid color(blue, darker);
        }

        .notification__list-date {
          font-size: 0.875rem;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
